import ReactDOM from 'react-dom';
import '@styles/common/_base.scss';

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback);
  };
};

export const shouldUpdateScroll = ({
  prevRouterProps: { location: prevLocation } = {},
  routerProps: { location } = {}
}) => {
  // If there's a modal being opened or closed, do not update the scrolling
  // position.
  
  const isModal = (location) => location && location.state && location.state.modal;
  return !isModal(prevLocation) && !isModal(location);
}
